import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";

type Props = {
  score: number;
  isPreview: Boolean;
};

export default function Score({ score, isPreview }: Props) {
  const { getLangLabel } = useWhiteLabelLan();
  const getLabel = (value: number) => {
    if (value < 4) return "good";
    if (value < 8) return "very good";
    return "excellent";
  };
  let scoreAux = Number(score);
  scoreAux = scoreAux > 0 ? scoreAux : 3;
  return (
    <div className="tw-inline-flex tw-justify-center tw-items-center">
      <span
        className={classNames(
          " rate-card tw-p-1 tw-rounded-md  tw-bg-inspira-purple-700 tw-text-white tw-max-h-8",
          isPreview ? "" : "tw-text-sm"
        )}
      >
        {Number(scoreAux).toFixed(1)}
      </span>
      <span
        className={classNames(
          "tw-ml-2  tw-capitalize",
          "tw-text-xs tw-w-full tw-font-medium"
        )}
      >
        {getLangLabel(getLabel(scoreAux))}
      </span>
    </div>
  );
}
